/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { useLocalStorage } from 'react-use'
import { Button, jsx } from 'theme-ui'
import Confetti from '../shared/Confetti'

const Styles = styled.div`
  .container {
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 600px;
    flex-direction: column;
  }
  .score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
    position: relative;
    gap: 10px;
  }

  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    flex: 2;
    position: relative;
  }

  .question-count {
    margin-bottom: 20px;
  }

  .question-count span {
    font-size: 12px;
  }

  .question-text {
    margin-bottom: 28px;
    font-size: 28px;
  }

  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(230, 153, 12);
    border-radius: 15px;
    text-align: center;
  }

  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    align-items: stretch;
    flex-wrap: wrap;
    flex: 1;
  }
  button {
    width: 100%;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.gray};
    border-radius: 15px;
    display: flex;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid black;
    cursor: pointer;
    text-align: left;
  }

  .correct {
    background-color: ${(props) => props.theme.colors.green};
  }

  .incorrect {
    background-color: ${(props) => props.theme.colors.highRisk};
  }

  button:hover {
    opacity: 0.5;
  }

  button:focus {
    outline: none;
  }

  button svg {
    margin-right: 5px;
  }
`

const Quiz = ({ id, questions, next, isFinal, basePath, courseSlug }) => {
  const [quizes, setQuizes] = useLocalStorage('quizes', [])
  const mappedQuestions = questions.map((q) => ({
    questionText: q.question,
    answerOptions: q.options.map((a) => ({
      answerText: a,
      isCorrect: a === q.answer,
    })),
  }))

  const hasDoneQuiz = quizes?.find((q) => id === q.id)?.questionKey

  const [currentQuestion, setCurrentQuestion] = useState(hasDoneQuiz || 0)
  const [showScore, setShowScore] = useState(hasDoneQuiz + 1 > questions.length)

  const [incorrect, setIncorrect] = useState(false)

  const handleAnswerOptionClick = (isCorrect, k) => {
    if (!isCorrect) {
      setIncorrect(k)
      return
    } else {
      setIncorrect(false)
    }

    const nextQuestion = currentQuestion + 1

    const quizExists = quizes.some((q) => q.id === id)

    setQuizes(
      quizExists
        ? quizes.map((q) =>
            q.id === id ? { ...q, questionKey: nextQuestion } : q
          )
        : [...quizes, { id, questionKey: nextQuestion }]
    )

    if (nextQuestion < mappedQuestions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      setShowScore(true)
    }
  }

  return (
    <Styles>
      <h4 sx={{ width: '100%', textAlign: 'center' }}>Test your knowledge!</h4>
      <div className="container">
        {showScore && <Confetti style={{ position: 'fixed' }} />}
        {showScore ? (
          <div className="score-section">
            {isFinal ? (
              <>
                You have completed this course! Congrats.
                <Button>
                  <Link
                    style={{ textDecoration: 'none', color: 'white' }}
                    to={`/course/${courseSlug}?quizComplete=true`}
                  >
                    Get Certificate
                  </Link>
                </Button>
              </>
            ) : (
              <>
                Congrats you passed this lesson! Go to the next one?
                <Button>
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                    }}
                    to={
                      !next
                        ? ''
                        : basePath
                        ? `/${basePath}/${next}`
                        : `/${next}`
                    }
                  >
                    Go to Next Lesson
                  </Link>
                </Button>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="question-section">
              <div className="question-count">
                <span>Question {currentQuestion + 1}</span>/
                {mappedQuestions.length}
              </div>
              <div className="question-text">
                {mappedQuestions[currentQuestion].questionText}
              </div>
            </div>
            <div className="answer-section">
              {mappedQuestions[currentQuestion].answerOptions.map(
                (answerOption, k) => (
                  <button
                    key={k}
                    onClick={() =>
                      handleAnswerOptionClick(answerOption.isCorrect, k)
                    }
                    className={incorrect === k ? 'incorrect' : ''}
                  >
                    {answerOption.answerText}
                  </button>
                )
              )}
              <div>{typeof incorrect === 'number' && 'Try again!'}</div>
            </div>
          </>
        )}
      </div>
    </Styles>
  )
}

export default Quiz

/** @jsx jsx */
import React from 'react'
import { Box, Button, jsx } from 'theme-ui'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'

import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'
import Quiz from '../components/quiz'
import QueryString from 'qs'
import { GradientBox } from '../components/shared/GradientBox'
import Header from '../components/Header'

const PostTemplate = ({ data, pageContext, location }) => {
  const search = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const {
    id,
    title,
    metaDescription,
    heroImage,
    body,
    publishDate,
    quizQuestions,
    subtitle,
    authors,
    videoUrl,
  } = data.contentfulPost

  const courses = data.allContentfulCourse.nodes

  const { basePath } = pageContext

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }
  const currentCourse = courses?.find((course) => course.id === search?.course)

  const currentPostIndex = currentCourse?.posts.findIndex(
    (post) => post.id === id
  )
  const nextPost = currentCourse?.posts[currentPostIndex + 1]
  const isFinal = currentCourse?.posts.length === currentPostIndex + 1

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <Header
        headerText={title}
        image={heroImage?.gatsbyImageData}
        imageCred={heroImage?.description}
        height={'60vh'}
        subHeader={subtitle}
        hideSearch
      />
      <Container sx={{ p: 20 }} mw>
        {/* {tags && <TagList tags={tags} />} */}
        <PostDetails
          date={publishDate}
          timeToRead={body.childMarkdownRemark.timeToRead}
          authors={authors}
        />

        {videoUrl && (
          <Box
            sx={{
              position: 'relative',
              maxWidth: '650px',
              margin: 'auto',
              height: '500px',
              textAlign: 'center',
              my: 20,
            }}
          >
            <iframe
              style={{
                top: 0,

                margin: 'auto',
                height: '100%',
                left: 0,
                position: 'absolute',
                border: 0,
              }}
              src={videoUrl}
              allowfullscreen
              scrolling="no"
              allow="encrypted-media;"
            ></iframe>
          </Box>
        )}
        <PageBody body={body} />
        {currentCourse && quizQuestions && quizQuestions.length > 0 ? (
          <Quiz
            id={id}
            questions={quizQuestions}
            next={`${nextPost?.slug}${location.search}`}
            basePath={basePath}
            courseSlug={search.courseSlug || currentCourse.slug}
            isFinal={isFinal}
          />
        ) : (
          <GradientBox style={{ mt: '10px' }}>
            <Box>
              <h1 sx={{ marginTop: '0px' }}>
                Want to learn stay safe at the beach?
              </h1>
              <p>
                Check out our free safety courses. They are fast and fun for all
                ages.
              </p>
            </Box>
            <Link to={'/safety-tips'}>
              <Button variant="white">Go To Free Safety Courses</Button>
            </Link>
          </GradientBox>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allContentfulCourse {
      nodes {
        id
        slug
        unfinished
        posts {
          id
          slug
        }
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      subtitle
      slug
      # course {
      #   id
      # }
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      # tags {
      #   title
      #   id
      #   slug
      # }
      authors {
        id
        name
        organizationName
        organizationLogo {
          gatsbyImageData(width: 60, height: 60)
        }
      }
      heroImage {
        title
        gatsbyImageData(layout: CONSTRAINED, width: 2400)
        description
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      videoUrl
      quizQuestions {
        question
        options
        answer
      }
    }
  }
`

export default PostTemplate

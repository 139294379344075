/** @jsx jsx */
import React from 'react'
import { Box, Flex, jsx } from 'theme-ui'
import styled from '@emotion/styled'

import { GatsbyImage } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  margin: 0 auto 2em;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  span {
    margin: 0 0.5rem;
  }
`

const Date = styled.p`
  display: inline-block;
`

const ReadingTime = styled.p`
  display: inline-block;
`

const PostDetails = (props) => {
  return (
    <Wrapper>
      <Date>📅 {props.date}</Date>
      <span>•</span>
      <ReadingTime>{`⏱️ ${props.timeToRead} min read `}</ReadingTime>
      {props.authors?.map((a, index) => (
        <Flex key={index} sx={{ alignItems: 'center', gap: '5px' }}>
          {a.organizationLogo && (
            <GatsbyImage
              title={a.organizationName}
              alt={a.organizationName}
              image={a.organizationLogo.gatsbyImageData}
            />
          )}
          <Box>
            <h2 sx={{ mb: 0 }}> {a.name}</h2>
            {a.organizationName}
          </Box>
        </Flex>
      ))}
    </Wrapper>
  )
}

export default PostDetails
